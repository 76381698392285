import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Saas/saas.style';
import Navbar from 'common/src/containers/Saas/Navbar';
import BannerSection from 'common/src/containers/Saas/BannerSection';
import FeatureSection from 'common/src/containers/Saas/FeatureSection';
import VisitorSection from 'common/src/containers/Saas/VisitorSection';
import ServiceSection from 'common/src/containers/Saas/ServiceSection';
import FaqSection from 'common/src/containers/Saas/FaqSection';
import Footer from 'common/src/containers/Saas/Footer';
import PricingSection from 'common/src/containers/Saas/PricingSection';
import TimelineSection from 'common/src/containers/Saas/TimelineSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import Helmet from 'react-helmet';

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="Logiciel de Business Plan en ligne • Créateur et Entrepreneur" />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
        })(window,document.documentElement,'async-hide','dataLayer',4000,
            {'GTM-K7ZDQRW':true});`,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-85201087-1', 'auto');
        ga('require', 'GTM-K7ZDQRW');
        ga('send', 'pageview');`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2028529634034124');
        fbq('track', 'PageView');`,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `(function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null};g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))),"mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g),history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split(".");2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,
            0)))}}var d=a;"undefined"!==typeof f?d=a[f]=[]:f="mixpanel";d.people=d.people||[];d.toString=function(b){var a="mixpanel";"mixpanel"!==f&&(a+="."+f);b||(a+=" (stub)");return a};d.people.toString=function(){return d.toString(1)+".people (stub)"};k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
            for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";c=e.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}})(document,window.mixpanel||[]);
        mixpanel.init("f1abedc49a5e762f6b4550441d0849b6");`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function (d, w, c) { (w[c] = w[c] || []).push(function() { try { w.yaCounter46174806 = new Ya.Metrika({ id:46174806, clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, trackHash:true }); } catch(e) { } }); var n = d.getElementsByTagName("script")[0], s = d.createElement("script"), f = function () { n.parentNode.insertBefore(s, n); }; s.type = "text/javascript"; s.async = true; s.src = "https://mc.yandex.ru/metrika/watch.js"; if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); } })(document, window, "yandex_metrika_callbacks");`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=2028529634034124&ev=PageView&noscript=1"
          />`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<div><img src="https://mc.yandex.ru/watch/46174806" style="position:absolute; left:-9999px;" alt="" /></div>`,
          }}
        />

        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <FeatureSection />
          <VisitorSection />
          <PricingSection />
          <ServiceSection />
          <TimelineSection />
          <FaqSection />
          <div
            style={{
              margin: '20px auto',
              maxWidth: '90%',
              width: '1170px',
              columns: '4 200px',
            }}
          >
            <h1 style={{ color: '#44b8a3', fontSize: '2em' }}>
              Le logiciel de Business Plan StartMyStory
            </h1>
            <p>
              Le Business Plan… étape obligatoire, passage obligé pour
              convaincre le banquier ou de futurs actionnaires que votre
              business model est en phase avec votre étude de marché ; que votre
              compte de résultat prévisionnel sera à la hauteur de votre
              dynamique entrepreneuriale. Ou tout simplement en tant que porteur
              de projet entrepreneurial, le besoin de mettre formaliser
            </p>

            <p>
              Ou tout simplement en tant que porteur de projet entrepreneurial,
              le besoin de mettre formaliser par écrit dans un logiciel le
              projet de création d’entreprise qui vous tient à cœur depuis des
              années !
            </p>

            <p>
              Avec l’application simple d’utilisation de business plan
              StartMyStory, nous parions que vous allez réussir la création de
              votre future activité. Alors, pour vous y aider, nous mettons à
              votre disposition de nombreuses fonctionnalités et options de
              gestion de business plan sur-mesure, professionnelles et très
              faciles à utiliser. Une fois que vous aurez réalisé votre business
              plan dans le logiciel StartMyStory, vous allez pouvoir disposer
              d’une vision transversale de vos principaux indicateurs
              comptables et de financement de votre exploitation :
              <ul>
                <li>- Compte de résultat</li>
                <li>- Bilan</li>
                <li>- Suivi de trésorerie</li>
                <li>- Graphiques de votre budget prévisionnel</li>
              </ul>
            </p>
            <br />
            <h2>Pourquoi utiliser un logiciel de business plan en ligne ?</h2>
            <p>
              Vous êtes créateur d’entreprise ? vous êtes chef d’entreprise ?
            </p>
            <p>
              … de n’importe quelle entreprise (freelance, indépendant,
              auto-entrepreneur, micro-entreprise, entreprise individuelle,
              petite entreprise, TPE, PME ou franchiseur…)
            </p>

            <p>
              …dans n’importe quel domaine d’activité (fleuriste, architecte,
              consultant, garagiste, paysagiste, agence de communication,
              boulanger, coiffeur…)
            </p>
            <p>
              {' '}
              Ou tout simplement vous envisagez de créer une nouvelle activité,
              filiale, division, un lancement de produit ou de service… Et
              souhaitez évaluer votre projet au travers de sa capacité
              d’autofinancement, taux de marge, flux de trésorerie ou plan de
              financement. Vous avez besoin de construire un business plan afin
              de connaître la rentabilité de votre projet et développer votre
              entreprise ? StartMyStory vous aide efficacement à réaliser votre
              prévisionnel d’activité ; et à suivre l’avancée de votre
              prévisionnel d’activité au fil des mois.
            </p>
            <br />
            <h2>
              Quel logiciel de business plan pour un bon suivi d'activité ?
            </h2>
            <p>
              Mais concrètement, c’est quoi un bon logiciel de business plan ?
              Pourquoi utiliser un logiciel de business plan d’ailleurs ? Et
              comment construire son suivi d’activité dans un logiciel de
              business plan ?
            </p>
            <p>
              C’est très simple, le business plan, outil indispensable pour
              établir le bilan prévisionnel ou tout simplement pour évaluer
              votre projet entrepreneurial, est la traduction écrite du projet
              que vous avez en tête depuis des années. Vous allez utiliser un
              logiciel de business plan en ligne, accessible depuis votre
              ordinateur, votre tablette ou même votre smartphone pour plus de
              facilité de travail.
            </p>
            <p>
              StartMyStory, logiciel en ligne de business plan, vous permet de
              construire et de rédiger de façon efficace et professionnelle le
              prévisionnel de votre future activité. C’est un outil logiciel en
              ligne aujourd’hui indispensable au moment de la création d’une
              entreprise ou lorsqu’une nouvelle activité nécessite de bâtir des
              perspectives de rentabilité.
            </p>
            <br />
            <h2>Comment choisir son logiciel de business plan ?</h2>
            <p>
              Parmi toutes les solutions de gestion du marché, la vocation du
              logiciel de business plan StartMyStory est très simple… vous aider
              de notre mieux à créer le projet qui vous tient à cœur, et le
              gérer au fil des mois sous forme de tableau de bord d’activité.
            </p>
            <p>
              Pour cela, nous pensons que la première étape incontournable
              consiste à vous assurer que votre projet est économiquement
              rentable. Ainsi, une fois complété au sein de votre application de
              business plan les différentes prévisions de votre plan de
              financement, vous aurez une vue claire de votre seuil de
              rentabilité et de votre besoin en fonds de roulement. C’est
              l’ensemble de votre stratégie d’entreprise qui va gagner en
              performance.
            </p>
            <p>
              Fini les fichiers sous tableur Excel avec des macros bloquées, des
              freewares qui n’en sont pas ou des téléchargements de modèles de
              business plan qui ne correspondent pas avec les spécificités «
              métier » de voter activité !
            </p>
            <p>
              Nous mettons à votre disposition un logiciel de business plan
              professionnel et très facile à utiliser ; véritable outil d’aide
              au pilotage de votre activité et à l’aide à la décision au
              quotidien. Vous disposez d’une checklist des principaux postes de
              produits et charges. Ils constituent une trame exemple de votre
              business plan, servant de modèle à votre prévisionnel d’activité.
              Logiciel d’évaluation de votre projet, établir un business plan
              n’a jamais été aussi simple avec le logiciel de prévisionnel
              d’activité StartMyStory !
            </p>

            <p>
              Soyez convaincant auprès de vos partenaires financiers, de vos
              investisseurs ou futurs clients ! Le logiciel de business plan
              StartMyStory, application en ligne professionnelle de référence
              pour créer, rédiger et élaborer un prévisionnel d’activité de
              grande qualité pour votre création d’entreprise, deviendra le
              partenaire indispensable pour créer votre propre modèle de
              business plan.
            </p>
            <br />
            <h2>Quel est le meilleur logiciel de business plan ?</h2>
            <p>
              Le business plan doit être réalisé lorsque votre projet commence à
              se structurer et que vous ressentez la nécessité « d’y voir clair
              ». Pour cela vous devez pouvoir faire confiance à un excellent
              logiciel, accessible en ligne depuis internet facilement et
              rapidement (connexion via Facebook ou LinkedIn Connect, depuis
              votre ordinateur, tablette ou smartphone), sécurisé et compatible
              RGPD.
            </p>
            <p>
              Les étapes de travail dans un logiciel de business plan de qualité
              sont relativement simples :
            </p>
            <ul>
              <li>
                Prévoyez les différents postes de produits et charges pour
                estimer votre Chiffre d’Affaires et vos flux de trésorerie
              </li>
              <li>
                Adaptez vos prévisions aux réalités économiques (équilibres de
                marge, flux de trésorerie, financement des immobilisations…)
                afin de dégager de la trésorerie pour développer votre
                entreprise et financer le besoin en fonds de roulement de votre
                projet
              </li>
              <li>
                Toujours depuis votre logiciel de business plan, remplissez le
                dossier de présentation de votre business plan (présentation de
                tous les points clés de votre plan d’affaires), et imprimez-le !
              </li>
            </ul>

            <p>
              StartMyStory, meilleur logiciel de business plan en ligne du
              marché, vous aide efficacement à réaliser votre prévisionnel
              d’activité.
            </p>
            <p>
              Et n’oubliez pas, plus qu’un Business Plan, StartMyStory, c’est le
              début de votre histoire…
            </p>

            <p>Alors c’est parti !</p>
          </div>
          <Footer />
          <script
            dangerouslySetInnerHTML={{
              __html: `mixpanel.track("homepage_vue");`,
            }}
          />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
